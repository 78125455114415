const Menu = () => {

  const btn = document.getElementById('hamburger')
  const header = document.querySelector('header')

  if (btn && header) {
    btn.addEventListener('click', () => {
      header.classList.toggle('open')
    })
  }
}
export default Menu
