import Masonry from 'masonry-layout'

const BlogGrid = () => {
  console.log('init masonry')
  const msnry = new Masonry( '.blog-entries', {
    gutter: 50
  });

}
export default BlogGrid
